import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signInWithCustomToken as fbSignInWithCustomToken,
  linkWithPhoneNumber as fbLinkWithPhoneNumber,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  updateProfile,
  UserCredential,
  ActionCodeSettings,
  signInWithPhoneNumber as fbSignInWithPhoneNumber,
  ApplicationVerifier,
  ConfirmationResult,
  OAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  signOut,
} from 'firebase/auth';
import { firebaseAuth, getFirebaseUser } from '~/lib/firebase';
import { Providers } from '~/usecases/auth-use-case';

export const currentUser = () => firebaseAuth.currentUser;

export const logout = (): Promise<void> => signOut(firebaseAuth);

export const signInWithCustomToken = (token: string): Promise<UserCredential> =>
  fbSignInWithCustomToken(firebaseAuth, token);

export const sendRegisterByEmailEmail = (
  email: string,
  directlyToPayment?: boolean,
  sessionID?: string,
  isPerkLP?: boolean
): Promise<void> => {
  let callbackUrl = `${
    process.env['NEXT_PUBLIC_BASE_URL'] || 'https://teller.jp/'
  }auth/finish_register?e=${btoa(email)}&utm_source=authc&utm_medium=email`;
  if (directlyToPayment) {
    callbackUrl += '&p=1';
  }
  if (isPerkLP) {
    callbackUrl += '&from=perklp';
  }
  if (sessionID) {
    callbackUrl += `&sID=${sessionID}`;
  }
  const actionCodeSettings: ActionCodeSettings = {
    url: callbackUrl,
    handleCodeInApp: true,
  };
  return sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings);
};

export const checkEmailAvailability = (email: string): Promise<boolean> =>
  new Promise<boolean>((resolve) => {
    fetchSignInMethodsForEmail(firebaseAuth, email).then((signInMethods) => {
      resolve(signInMethods.length === 0);
    });
  });

export const createUser = (
  email: string,
  password: string
): Promise<UserCredential | undefined> =>
  createUserWithEmailAndPassword(firebaseAuth, email, password);

export const setUsername = (username: string): Promise<void> =>
  new Promise<void>(async (resolve, reject) => {
    const user = await getFirebaseUser();
    if (!user) {
      reject(new Error('no-current-user'));
      return;
    }
    updateProfile(user, {
      displayName: username,
    })
      .then(() => resolve())
      .catch((error) => {
        reject(error);
      });
  });

export const signInWithPhoneNumber = (
  phone: string,
  appVerifier: ApplicationVerifier
): Promise<ConfirmationResult> =>
  fbSignInWithPhoneNumber(firebaseAuth, phone, appVerifier);

export const linkWithPhoneNumber = (
  phone: string,
  appVerifier: ApplicationVerifier
): Promise<ConfirmationResult> =>
  new Promise((resolve, reject) => {
    if (!firebaseAuth.currentUser) {
      reject();
      return;
    }
    resolve(
      fbLinkWithPhoneNumber(firebaseAuth.currentUser, phone, appVerifier)
    );
  });

export const signInByEmailAndPassword = (
  email: string,
  password: string
): Promise<UserCredential> =>
  new Promise<UserCredential>((resolve, reject) => {
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then((credentials) => {
        resolve(credentials);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getProvider = (
  provider: string
): OAuthProvider | GoogleAuthProvider | null => {
  let authProvider: OAuthProvider | GoogleAuthProvider | null = null;
  switch (provider) {
    case Providers.APPLE: {
      authProvider = new OAuthProvider(Providers.APPLE);
      authProvider.addScope('name');
      authProvider.addScope('email');

      break;
    }
    case Providers.GOOGLE: {
      authProvider = new GoogleAuthProvider();
      authProvider.addScope('email');

      break;
    }
    case Providers.TWITTER: {
      authProvider = new TwitterAuthProvider();

      break;
    }
    // No default
  }
  if (authProvider) {
    authProvider.setCustomParameters({
      locale: 'ja',
    });
  }
  return authProvider;
};
