import {
  // connectAuthEmulator,
  getAuth,
  type User,
  type UserCredential,
} from 'firebase/auth';
import { firebaseApp } from './firebase-app';

export type FirebaseUser = User;
export type FirebaseUserCredential = UserCredential;

export const firebaseAuth = getAuth(firebaseApp);

// For local development
// connectAuthEmulator(firebaseAuth, 'http://localhost:9099');

export const getFirebaseUser = (): Promise<FirebaseUser | null> =>
  new Promise((resolve) => {
    if (firebaseAuth.currentUser) {
      resolve(firebaseAuth.currentUser);
      return;
    }

    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      resolve(user);
      unsubscribe();
    });
  });
