import { FirebaseError } from 'firebase/app';
import type {
  ApplicationVerifier,
  ConfirmationResult,
  UserCredential,
} from 'firebase/auth';
import { convertUserResponseToTellerUser } from '~/common/converter/user/teller-user';
import type { TellerUser } from '~/models/teller-user';
import * as fbAuthRepository from '~/repositories/firebase-auth-repository';
import { postAnswer } from '~/repositories/profiling-repository';
import { fetchMe, updateMe } from '~/usecases/me-use-case';

// The operation that should be done after catching "requires-recent-login" Firebase error
// so we can retry it right after login
// Basically gather where we were and what we wanted to do when the reauthenticate error came
// and pass these to the login flows (SMS or SNS provider) so they know where to go and resume operations
// after login succeed
export enum AfterLoginOperations {
  UPDATE_EMAIL_AND_SEND_VERIFICATION = 'linkEmail',
  LINK_EMAIL_PWD_CREDENTIAL = 'linkEmailPwdCredential',
}

export type AuthResponse = {
  succeed: boolean;
  errorMsg: string;
};

export const Providers = {
  GOOGLE: 'google.com',
  APPLE: 'apple.com',
  TWITTER: 'twitter.com',
} as const;
export type Providers = typeof Providers[keyof typeof Providers];

export const providerString = (provider: Providers): string => {
  // Pretty much use `provider` parameter for constructing the page title
  // First character in uppercase and remove ".com"
  // google -> Google, facebook -> Facebook ...
  if (!provider) {
    return '';
  }
  return (
    (provider as string).charAt(0).toUpperCase() +
    (provider as string).slice(1, -4)
  );
};

export const sendRegisterByEmailEmail = (
  email: string,
  directlyToPayment?: boolean,
  sessionID?: string,
  isPerkLP?: boolean
): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    fbAuthRepository.checkEmailAvailability(email).then((notRegistered) => {
      if (!notRegistered) {
        reject(new Error('このメールアドレスは使用できません。'));
        return;
      }
      fbAuthRepository
        .sendRegisterByEmailEmail(email, directlyToPayment, sessionID, isPerkLP)
        .then(() => {
          resolve();
        });
    });
  });

export const isEmailAvailable = (email: string): Promise<boolean> =>
  fbAuthRepository.checkEmailAvailability(email);

export const createUser = (
  email: string,
  password: string,
  username: string,
  gender: string,
  birthday: Date
): Promise<TellerUser> =>
  new Promise((resolve, reject) => {
    fbAuthRepository
      .createUser(email, password)
      .then(() => {
        fbAuthRepository.setUsername(username).then(() => {
          fetchMe().then((me) => {
            const user = me.user!;
            user.name = username;
            updateMe({
              ...user,
              id: user.id ?? '',
            }).then(() => {
              postAnswer(gender, birthday).then(() => {
                resolve(convertUserResponseToTellerUser(user));
              });
            });
          });
        });
      })
      .catch((error) => {
        reject(convertAuthError(error));
      });
  });

export const updateUser = (
  username: string,
  gender: string,
  birthday: Date
): Promise<TellerUser> =>
  new Promise((resolve, reject) => {
    fbAuthRepository
      .setUsername(username)
      .then(() => {
        fetchMe().then((me) => {
          const user = me.user!;
          user.name = username;
          updateMe({ ...user, id: user.id ?? '' }).then(() => {
            postAnswer(gender, birthday).then(() => {
              resolve(convertUserResponseToTellerUser(user));
            });
          });
        });
      })
      .catch((error) => {
        reject(convertAuthError(error));
      });
  });

export const signInWithPhoneNumber = (
  phone: string,
  appVerifier: ApplicationVerifier
): Promise<ConfirmationResult> =>
  fbAuthRepository.signInWithPhoneNumber(phone, appVerifier);

export const signInWithCustomToken = (token: string): Promise<UserCredential> =>
  fbAuthRepository.signInWithCustomToken(token);

export const loginByEmailAndPassword = async (
  email: string,
  password: string
): Promise<UserCredential> => {
  try {
    return fbAuthRepository.signInByEmailAndPassword(email, password);
  } catch (error: any) {
    throw new Error(suitableErrorMsg(error?.code));
  }
};

export const logout = (): Promise<void> => fbAuthRepository.logout();

export const linkWithPhoneNumber = (
  phone: string,
  appVerifier: ApplicationVerifier
): Promise<ConfirmationResult> =>
  fbAuthRepository.linkWithPhoneNumber(phone, appVerifier);

const convertAuthError = (error: Error) => {
  return error instanceof FirebaseError
    ? new Error(suitableErrorMsg(error.code))
    : new Error('不明なエラーです。');
};

const suitableErrorMsg = (code: string): string => {
  let msg;
  switch (code) {
    case 'auth/wrong-password': {
      msg = 'メールアドレスおよびパスワードをご確認ください。';
      break;
    }
    case 'auth/too-many-requests': {
      msg =
        'ログイン試行の回数が上限を超えました。\nしばらくしてからもう一度お試しください。';
      break;
    }
    case 'auth/user-not-found': {
      msg = 'メールアドレスは有効ではありません。';
      break;
    }
    default: {
      msg =
        '不明なエラーが発生しました。しばらくしてからもう一度お試しください';
    }
  }

  return msg;
};
