import type { User } from 'firebase/auth';
import { signIn, type SignInResponse } from 'next-auth/react';

type SignInOptions = {
  /**
   * Specify to which URL the user will be redirected after signing in. Defaults to the page URL the sign-in is initiated from.
   *
   * [Documentation](https://next-auth.js.org/getting-started/client#specifying-a-callbackurl)
   */
  callbackUrl?: string;
  /** [Documentation](https://next-auth.js.org/getting-started/client#using-the-redirect-false-option) */
  redirect?: boolean;
};

export const signInNextAuthFromFirebaseUser = async (
  firebaseUser: User,
  fetchTellerUser?: boolean,
  signInOptions?: SignInOptions
): Promise<SignInResponse | undefined> => {
  const idToken = await firebaseUser.getIdToken(true);
  const avoidRedirect = (() => {
    if (firebaseUser.isAnonymous) return true;

    if (firebaseUser.metadata.creationTime) {
      const createdAt = new Date(firebaseUser.metadata.creationTime);
      const now = new Date();

      const differenceInSeconds = (now.getTime() - createdAt.getTime()) / 1000;
      // If the user has been registered in the last 5 minutes, avoid redirect
      // that will be handled in finish registration page
      if (differenceInSeconds < 60 * 5) {
        return true;
      }
    }
    return false;
  })();

  const result = await signIn('id-token', {
    idToken,
    fetchTellerUser,
    redirect: !avoidRedirect && signInOptions?.redirect,
    callbackUrl: avoidRedirect ? undefined : signInOptions?.callbackUrl ?? '/',
  });

  return result;
};
